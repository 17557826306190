
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Index from "./Components/Index";
import BetaUser from "./Components/BetaUser";
import { config } from "./Config";
import Policy from "./Components/Policy";

function App() {
  return (
    <div className="">
      <Router>
        <ToastContainer theme='colored' />

        <Routes>
          <Route path={config.appurl} Component={Index} />
          <Route path={`${config.appurl}beta-user`} Component={BetaUser} />
          <Route path="/privacy-policy" Component={Policy} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
