import React from "react";
import "./Policy.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Policy = () => {
  return (
    <div>
      <Navbar />
      <div>
        <div className="policy-banner">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      <section
        className="bd-about-area-two py-md-5 policy-responsive"
        style={{ width: "95%" }}
      >
        <div className="policy-content container-box">
          <p>
            <i className="bi bi-arrow-right me-2"></i>At Nutri Connect Pro, we
            take your privacy seriously. This Privacy Policy outlines how we
            collect, use, disclose, and protect your personal information when
            you use our website, mobile application, or any related services. By
            using our platform, you consent to the practices described in this
            policy.
          </p>
          <h3>1. Information We Collect </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> Nutri Connect Pro may
            collect personal and sensitive data when you register as a
            dietitian, use our platform, or interact with our services. The
            types of information we collect include:{" "}
          </p>
          <p className="mb-0">
            <strong>Personal Information</strong>: Name, email address, phone
            number, and any other details provided during registration or
            communication.{" "}
          </p>
          <p className="mb-0">
            <strong>Health and Dietary Information</strong>: Customizable diet
            plans, nutritional preferences, and progress tracking data for you
            and your clients.
          </p>
          <p>
            <strong>Usage Data</strong>: Information about how you interact with
            our platform, including IP addresses, browser type, device
            information, and browsing behavior.
          </p>
          <h3>2. How We Use Your Data </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i>We use the information
            collected for various purposes, including:{" "}
          </p>
          <ul>
            <li>To provide and maintain our services. </li>
            <li>
              To offer personalized diet plans and progress tracking for your
              clients.{" "}
            </li>
            <li>To manage your account and communicate with you. </li>
            <li>
              To improve the platform by analyzing trends and user behavior.{" "}
            </li>
            <li>To ensure the security of our services. </li>
          </ul>
          <h3>3. Data Retention and Deletion </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i>We will retain your
            personal and health-related data only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law. If you no longer wish to use our services, you may contact
            us to request deletion of your account and associated data. We will
            remove your information within a reasonable timeframe.{" "}
          </p>
          <h3>4. How We Share Your Data</h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> Nutri Connect Pro does
            not sell or rent your personal data. We may share information with
            third parties only in the following circumstances:{" "}
          </p>
          <p className="mb-0">
            <strong>Service Providers :</strong> We may share data with
            third-party companies who provide services to help run our platform
            (e.g., payment processors, hosting services, etc.).
          </p>
          <p>
            <strong>Legal Compliance :</strong> We may disclose your data if
            required by law, such as complying with subpoenas, court orders, or
            legal obligations.{" "}
          </p>
          <h3>5. Security of Your Data </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> We employ advanced
            encryption methods and security practices to protect your personal
            and sensitive data. However, no method of transmission over the
            internet is entirely secure, and we cannot guarantee absolute
            security. You are responsible for maintaining the confidentiality of
            your login credentials.{" "}
          </p>
          <h3>6. Cookies and Tracking Technologies </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> Nutri Connect Pro uses
            cookies and similar technologies to enhance your user experience.
            These technologies allow us to:{" "}
          </p>
          <ul>
            <li>Customize the platform based on your preferences. </li>
            <li>Track usage patterns to improve our services. </li>
            <li>Provide relevant advertising and analytics. </li>
            <li>
              You can manage cookie preferences through your browser settings.{" "}
            </li>
          </ul>

          <h3>7. Third-Party Services </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> Our platform may contain
            links to third-party websites or services. These external sites have
            their own privacy policies, and we are not responsible for the
            privacy practices of these third parties. We encourage you to read
            their privacy policies before providing any personal information.{" "}
          </p>
          <h3>8. Children’s Privacy </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> Nutri Connect Pro is not
            intended for use by children under the age of 13. We do not
            knowingly collect personal information from children under 13. If
            you believe we have inadvertently collected such information, please
            contact us immediately, and we will take steps to remove it.{" "}
          </p>
          <h3>9. Your Data Protection Rights (GDPR) </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i>If you are located in the
            European Economic Area (EEA), you have certain rights regarding your
            personal data, including:{" "}
          </p>
          <p className="mb-0">
            <strong>Right of Access: </strong>You have the right to request
            access to the personal information we hold about you.{" "}
          </p>
          <p className="mb-0">
            <strong>Right to Rectification: </strong>You can request corrections
            to any inaccurate or incomplete data.{" "}
          </p>
          <p className="mb-0">
            <strong>Right to Erasure: </strong> You may request that we delete
            your personal data under certain conditions.{" "}
          </p>
          <p className="mb-0">
            <strong>Right to Restrict Processing: </strong>
            You may request that we limit how we process your personal data.
          </p>
          <p className="mb-0">
            <strong>Right to Data Portability : </strong>
            You can request a copy of your personal data in a structured,
            commonly used, and machine-readable format.
          </p>
          <p className="mb-0">
            <strong>Right to Withdraw Consent:</strong>
            If we process your data based on consent, you can withdraw that
            consent at any time.
          </p>
          <p>
            To exercise these rights, please contact us using the contact
            details provided below.{" "}
          </p>
          <h3>10. Privacy Policy Updates </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i> We may update this
            Privacy Policy periodically to reflect changes in our services or
            legal obligations. Any changes will be posted on this page, and we
            will indicate the effective date of the update. We recommend that
            you review this policy regularly.{" "}
          </p>
          <h3>11. Contact Us </h3>
          <p>
            <i className="bi bi-arrow-right me-2"></i>If you have any questions
            or concerns about this Privacy Policy or how your data is handled,
            please contact us at:{" "}
          </p>
          <p className="mb-0">Nutri Connect Pro </p>
          <p className="mb-0">
            Email:{" "}
            <a
              className="text-dark-purple"
              href="mailto:contact@nutriconnectpro.com"
            >
              contact@nutriconnectpro.com
            </a>
          </p>
          <p className="mb-0">
            Phone:{" "}
            <a className="text-dark-purple" href="callto:+91-7202882277">
              +91-7202882277
            </a>
          </p>
          <p className="mb-0">
            Website{" "}
            <a
              className="text-dark-purple"
              href="https://www.nutriconnectpro.com"
              target="_blank"
            >
              www.nutriconnectpro.com
            </a>
          </p>
          <p>
            By using Nutri Connect Pro’s services, you consent to the collection
            and use of your information as described in this Privacy Policy.{" "}
          </p>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Policy;
