import React from "react";
import { Link } from "react-router-dom";
import { config } from "../Config";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-box">
        <div className="row py-4 text-md-start text-center">
          <div className="col-md-6 my-2 paragraph text-dark-purple">
            <span className="text-dark-purple me-2">
              <Link className="text-dark-purple" to={`${config.appurl}privacy-policy`}>Privacy Policy</Link>
            </span>
            |
            <span className="ms-2">
              Copyrights © {new Date().getFullYear()} - All Rights Reserved
            </span>
          </div>
          <div className="col-md-6 my-2 paragraph text-dark-purple text-md-end">
            <span>
              Designed and Developed by{" "}
              <a
                href="https://www.zithas.com"
                target="_blank"
                rel="noopener"
                className="text-decoration-none text-dark-purple"
              >
                Zithas Technologies
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
